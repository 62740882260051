import { Stack } from '@mantine/core';
import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import { useProductOverviewQuery } from '@assemblio/frontend/data-access';
import { ActionBar } from './components/ActionBar/ActionBar';
import { TabsContainer } from './components/TabContainer/TabContainer';
import { useExplorerTitle } from '../../hooks';
import classes from './Product.module.scss';
import { ErrorScreen } from '../../components/ErrorScreen/ErrorScreen';
import { useProductWebsocketListener } from './hooks/productWebSocket.listener';

export const Product = () => {
  const { productId } = useExplorerRouteParams();
  useProductWebsocketListener(productId);
  const { data, isLoading, isError } = useProductOverviewQuery(productId);
  useExplorerTitle(data?.name, {
    title: {
      state: data?.state,
    },
  });

  if (isError) {
    return <ErrorScreen />;
  }

  return (
    <Stack className={classes.wrapper} h={'100%'}>
      <ActionBar product={data} />
      <TabsContainer isLoading={isLoading} product={data} />
    </Stack>
  );
};
