export enum OperationalResourceUnits {
  // ToDo: add all the needed units for weight, length, density, ..etc.
  METER = 'm',
  CENTIMETER = 'cm',
  SQUARE_CENTIMETER = 'cm2',
  AMPERE = 'A',
  CUBIC_CENTIMETER = 'cm3',
  PIECE = 'pcs',
  KILOMETER = 'km',
  CELSIUS = '°c',
  FAHRENHEIT = '°f',
  KELVIN = '°k',
  LITER = 'l',
  MILLILITER = 'ml',
}
