import { useModelStore } from '@assemblio/frontend/stores';
import { Grid as DreiGrid } from '@react-three/drei';

interface GridProps {
  radius: number;
  spacing: number;
  lineColor: string;
  lineWidth: number;
  enabled: boolean;
}

export const Grid = (props: GridProps) => {
  const { radius, spacing, lineColor, lineWidth, enabled } = props;
  const yOffset = useModelStore((state) => state.yOffset);

  if (!enabled) return null;

  return (
    <DreiGrid
      position={[0, yOffset, 0]}
      cellSize={1 / (spacing * spacing)}
      sectionSize={1 / radius}
      cellColor={lineColor}
      cellThickness={lineWidth}
      sectionThickness={lineWidth / 2}
      infiniteGrid={false}
      sectionColor={lineColor}
    />
  );
};
