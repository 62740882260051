import { useProductExportJobQuery } from '@assemblio/frontend/data-access';
import { Divider } from '@mantine/core';
import { JobCard } from '../../../../../../../../components/JobCard/JobCard';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useEventStore } from '@assemblio/frontend/stores';
import { ExportJobDto } from '@assemblio/shared/dtos';
import { JobState } from '@assemblio/type/job';

interface ExportJobsListProps {
  productId: string | undefined;
}

export const ExportJobsList = ({ productId }: ExportJobsListProps) => {
  const socket = useEventStore((state) => state.socket);
  const queryClient = useQueryClient();
  const { data: exportJobs, isLoading } = useProductExportJobQuery(productId);
  const [tempEvents, setTempEvents] = useState<{ jobId: string; progress?: number; state?: JobState }[]>([]);
  const [_, setRefresh] = useState<boolean>(false);

  const exportJobsIsEmpty = exportJobs && exportJobs.length === 0;

  const handleUpdate = (event: { jobId: string; progress?: number; state?: JobState }) => {
    let saveTemp = true;
    let invalidate = false;
    setRefresh(false);
    queryClient.setQueryData<ExportJobDto[]>(['export-jobs', productId], (jobs = []) => {
      for (let i = 0; i < jobs.length; i++) {
        if (jobs[i].productId === productId && jobs[i].id === event.jobId) {
          jobs[i] = {
            ...jobs[i],
            progress: (event.progress || 0) * 100,
            ...(event.state && { state: event.state }),
          };
          if (event.state === JobState.Completed) {
            invalidate = true;
          }
          saveTemp = false;
          break;
        }
      }
      if (saveTemp) setTempEvents([...tempEvents, event]);
      setRefresh(true);
      return [...jobs];
    });
    if (invalidate) {
      queryClient.invalidateQueries(['artefacts', productId]);
      queryClient.invalidateQueries(['export-jobs', productId]);
    }
    return saveTemp;
  };

  useEffect(() => {
    setTempEvents(tempEvents.filter(handleUpdate));
  }, [exportJobs?.length]);

  useEffect(() => {
    if (!productId) return;
    socket.emit('subscribe-product', productId);
    socket.on('product-job-update', handleUpdate);
    return () => {
      socket.off('product-job-update', handleUpdate);
      socket.emit('unsubscribe-product', productId);
    };
  }, [productId]);

  if (isLoading || !exportJobs || exportJobsIsEmpty) return null;
  return (
    <>
      {exportJobs?.map((job) => <JobCard key={job.id} job={job} />)}
      <Divider />
    </>
  );
};
