import { useSequenceStore, useUIStore } from '@assemblio/frontend/stores';
import { Transform } from '@assemblio/type/3d';
import { Line } from '@react-three/drei';
import { useEffect, useMemo, useRef } from 'react';
import { Vector3 } from 'three';
import { Line2 } from 'three-stdlib';

interface Props {
  individual?: boolean;
}

export const LineRenderer = (props: Props) => {
  const showLineRenderer = useUIStore((state) => state.showLineRenderer);
  const lineRef = useRef<Line2>(null);
  const selectedStep = useUIStore.getState().selectedStep;

  const step = useSequenceStore(
    (state) => selectedStep && state.stepGroups[selectedStep.index.stepGroupIndex]?.steps[selectedStep.index.stepIndex]
  );

  const path = useMemo(() => {
    if (step?.data?.path) {
      return step.data.path.map((transform: Transform) => {
        const { x, y, z } = transform.position;
        return new Vector3(x, y, z);
      });
    }
    return [];
  }, [step]);

  useEffect(() => {
    if (lineRef.current) {
      lineRef.current.computeLineDistances();
    }
  }, [lineRef, path]);

  const lineProps = useMemo(
    () => ({
      color: 0xf59c1b,
      lineWidth: 2,
      points: path,
      dashed: true,
      dashSize: 0.004,
      gapSize: 0.004,
    }),
    [path]
  );

  if (!showLineRenderer || path.length === 0) {
    return null;
  }
  return <Line ref={lineRef} {...lineProps} />;
};
