import { MouseEvent, useEffect, useRef } from 'react';

// A hook to track mouse position using refs to avoid re-renders, almost identical to mantines useMouse but mouse change doesn't update state
export function useMousePosition<T extends HTMLElement = any>(
  options: { resetOnExit?: boolean } = { resetOnExit: false }
) {
  const positionRef = useRef({ x: 0, y: 0 });

  const ref = useRef<T>(null);

  const setMousePosition = (event: MouseEvent<HTMLElement>) => {
    if (ref.current) {
      const rect = event.currentTarget.getBoundingClientRect();

      const x = Math.max(0, Math.round(event.pageX - rect.left - (window.pageXOffset || window.scrollX)));

      const y = Math.max(0, Math.round(event.pageY - rect.top - (window.pageYOffset || window.scrollY)));

      positionRef.current = { x, y };
    } else {
      positionRef.current = { x: event.clientX, y: event.clientY };
    }
  };

  const resetMousePosition = () => {
    positionRef.current = { x: 0, y: 0 };
  };

  useEffect(() => {
    const element = ref?.current ? ref.current : document;
    element.addEventListener('mousemove', setMousePosition as any);

    if (options.resetOnExit) {
      element.addEventListener('mouseleave', resetMousePosition as any);
    }

    return () => {
      element.removeEventListener('mousemove', setMousePosition as any);
      if (options.resetOnExit) {
        element.removeEventListener('mouseleave', resetMousePosition as any);
      }
    };
  }, [ref.current]);

  const getMousePosition = () => positionRef.current;

  return { ref, getMousePosition };
}
