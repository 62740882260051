import { PaginatedUserDto, UserQueryDto, UserDto } from '@assemblio/shared/dtos';
import { axiosHandler } from '../utils/axios-utils';

export const fetchV1CurrentUser = async (): Promise<UserDto> => {
  return axiosHandler('get', 'v1/users/me');
};

export const fetchTenantUsers = async (options: UserQueryDto): Promise<PaginatedUserDto> => {
  return axiosHandler('get', `/tenants/users`, {
    params: {
      exclude: `role:${options.exclude}`,
      searchBy: options.searchBy ? `email:${options.searchBy}` : null,
    },
  });
};
