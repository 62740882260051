import { useEventStore } from '@assemblio/frontend/stores';
import { Box, Button, Divider, Modal, Overlay, Stack, Text } from '@mantine/core';

export const InactiveWindowOverlay = () => {
  const socket = useEventStore((state) => state.socket);

  const setSessionActive = () => {
    socket.emit('activate-session');
  };
  return (
    <Overlay>
      <Modal withCloseButton={false} centered withinPortal onClose={() => null} opened={true}>
        <Stack gap={'0'}>
          <Text fw={700} size="xl" p={'lg'}>
            Window is inactive
          </Text>
          <Text c={'text-secondary'} px={'lg'}>
            You appear to be using the application in another browser tab or window. There can only be one active window
            at a time. If you want to continue editing here, please activate the window.
          </Text>
          <Divider my={'lg'} />
          <Box p={'lg'} pt={0}>
            <Button variant={'primary'} fullWidth onClick={setSessionActive}>
              Activate this window
            </Button>
          </Box>
        </Stack>
      </Modal>
    </Overlay>
  );
};
